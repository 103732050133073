import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";

/**
 * 查询会员套餐配置
 */
export function memberConfig() {
    return request({
        url: `${APICONFIG}/member/config`,
        method: 'get',
    })
}

/**
 * 查询qrcode
 */
export function createQrcode(uid, memberId) {
    return request({
        url: `${APICONFIG}/qrcode?uid=${uid}&memberId=${memberId}`,
        method: 'get',
    })
}


/**
 * 查询是否支付成功
 */
export function payQuery(uid) {
    return request({
        url: `${APICONFIG}/pay/query?uid=${uid}`,
        method: 'get',
    })
}




