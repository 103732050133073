<template>
  <div class="vip-inner" :style="{height: 520 + 'px'}">
    <div class="desc-panel">
      <div class="title">{{ left.title }}</div>
      <div class="sub-title">{{ left.subTitle }}</div>
      <a-divider style="background: #d4d3d3"/>
      <div v-for="item in left.item" class="item">
        <span class="point"></span>
        <span>{{ item }}</span>
      </div>

    </div>
    <div class="vip-detail">
      <a-tabs :activeKey="currentTabKey" @change="changTab">
        <a-tab-pane v-for="item in tabs" :key="item.id" :tab="item.name" :forceRender="true">
          <div class="details-panel">
            <div v-for="dt in item.memberDetails"
                 @click="clickCard(dt)"
                 :class="dt.id === memberId ? 'details-card-container details-card-checked':'details-card-container'">
              <span class="title">
                {{ dt.name }}
              </span>
              <div class="discount" v-if="dt.discount">
                  <span>
                    {{ dt.discount }}
                  </span>
              </div>
              <span style="margin-bottom: 5px">
                <span class="unit">¥</span>
                <span class="price">{{ dt.price }}</span>
                <span class="cost-price">原价:¥{{ dt.costPrice }}</span>
              </span>
              <span v-for="ds in dt.desc" class="desc">{{ ds }}</span>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>

      <div class="qrcode-container">
        <a-spin :spinning="spinning">
          <div class="qrcode">
            <img :src="qrcodeUrl" v-if="qrcodeUrl" style="height: 130px;">
            <img v-else @click="reloadQrcode  " src="/qrcode_refesh.jpg" style="height: 130px;"/>
          </div>
        </a-spin>

        <div style="padding-left:23px;">
          <p class="pay-app">
            <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAMFBMVEVHcEw1vE41vkw2v0s2v0w3v0wuvEQouj5r0Hql4q7I7c7i9uWM2pn///9TyGXz+/RBLjxoAAAABXRSTlMAOcL1+XDJrPQAAAEoSURBVHja1dXBkoQgDATQHTXdBAL8/9+uRekwE9ZRD3PYvnDwESFVwM9X85jm5WPm6fG0i5xm2fUkFzJteL6C5w0vV/AyYhAgcQEDQWNKKZrwDCOkvCcKPmLE/BrjB8ytbNc4xIzZpxxhhLwmIOY+Jh5gNgVqw6C1OQdYauuB1tzHpPwbF7feakVIXMIGopiqFWDE8lZ2JZb2X8Bh1+UASW9THYb1r0qpruFuGajPSoK1bnzpfPW4bzEytPLIa6R1XOGwhA0bY9tYbDOtdXzA1A2jZh8ZcFxLrE454uKx5GqUEgLTeeWynw6ot4ke93NXPDZ43AMdCh9if2ySiMNO2wsuJ1gg2npSVbPB45FTShGQQXmKeyAOn2e5deXeusxvPRO3HqD/ml/eDypshGCXOQAAAABJRU5ErkJggg=="
                alt="" style="width: 22px; border-radius: 10px; overflow: hidden;">
            微信支付</p>

          <div style="display: flex;align-items: center; width: 420px;margin-top: 10px;">
            <span style="font-size: 13px; color: #999999">应付金额：</span>
            <span class="price">¥ {{ price }}</span>
            <span class="discount-price">已优惠￥ {{ costPrice }}</span>
          </div>
          <div style="display: flex;align-items: center; width: 450px;margin-top: 10px;">
            <a-button type="link" style="padding: 0;font-size: 13px">
              我已阅读并同意
            </a-button>
            <span style="font-size: 13px;color: #f05a24">《会员服务协议》</span>
            <span style="font-size: 13px; margin-left: auto">剩余支付时间: <span
                style="color:red;">{{ countdown }}</span></span>
          </div>

        </div>
      </div>

      <div class="footer-desc">
        划线价格说明：商品展示的划横线价格为参考价，并非原价，该价格可能是商品在其他售卖渠道的历史售价、活动指导价、建议售价等或其他真实有依据的价格仅供您参考。
      </div>
    </div>
  </div>
</template>

<script>
import {createQrcode, memberConfig, payQuery} from "@/api/memberconfig";
import {generateUUID} from "ant-design-vue/lib/vc-select/util";

let defaultTime = 150
export default ({
  props: {
    height: {
      type: Number,
      default: 420,
    }
  },
  data() {
    return {
      left: {},
      tabs: [],
      currentTabKey: "-1",
      memberId: "-1",
      timer: null,
      countDownTimer: null,
      countdown: defaultTime,
      qrcodeUrl: null,
      spinning: false,
      uid: ''
    }
  },
  methods: {
    init() {
      memberConfig().then(res => {
        if (res.success) {
          this.tabs = res.data
          this.$nextTick(() => {
            this.findNormalInfo()
          })
        }
      })
    },
    findNormalInfo() {
      if (this.tabs.length > 0) {
        this.tabs.forEach(item => {
          if (item.checked === 1) {
            this.currentTabKey = item.id
            this.left = item
            this.findDefaultMember()
          }
        })
      }
    },
    findDefaultMember() {
      const currentMember = this.left.memberDetails.filter(item => item.checked === 1)
      this.memberId = currentMember[0].id
      this.clickCard(currentMember[0])
      // this.qrcodeUrl = null
      // this.createQrcode()
      // // this.$message.success("创建二维码" + this.memberId)
      // this.startCountdown()
    },
    changTab(key) {
      this.currentTabKey = key
      const desc = this.tabs.filter(item => item.id === key)
      this.left = desc[0] || {}
      this.findDefaultMember()
    },

    clickCard(card) {
      this.memberId = card.id
      this.qrcodeUrl = null
      this.price = card.price
      this.costPrice = card.costPrice - card.price
      this.createQrcode()
      // this.$message.success("创建二维码" + this.memberId)
      this.startCountdown()
    },

    startCountdown() {
      if (this.countDownTimer) {
        this.clearCountDownTimer()
        this.countdown = defaultTime
      }
      this.countDownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          // this.countdown = defaultTime
          this.qrcodeUrl = null
          this.clearCountDownTimer()
          // this.createQrcode()
        }
      }, 1000);
    },

    reloadQrcode() {
      this.startCountdown()
      // this.countdown = defaultTime
      this.createQrcode()
    },
    query() {
      this.timer = setInterval(() => {
        payQuery(this.uid).then(res => {
          if (res.success && res.data) {
            if (this.timer) {
              clearInterval(this.timer) // 此处的timer即 上文const的 timer
            }
            this.clearCountDownTimer()
            this.$emit('paySuccess')
          }
        })
      }, 1500)
    },

    clearCountDownTimer() {
      // this.countdown = defaultTime
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer) // 此处的timer即 上文const的 timer
      }
    },

    //考虑二维码服务端加密，客户端再解密？传输过程中被劫持，会不会返回的是第三方二维码？
    createQrcode() {
      this.spinning = true
      this.uid = generateUUID()
      createQrcode(this.uid, this.memberId).then(res => {
        if (res.data) {
          this.qrcodeUrl = res.data
        }
      }).finally(() => {
        this.spinning = false
      })
    }

  },
  mounted() {
    this.init()
    this.query()
    this.$once('hook:beforeDestroy', () => {
      if (this.timer) {
        clearInterval(this.timer) // 此处的timer即 上文const的 timer
      }
      this.clearCountDownTimer()
    })

  }
})
</script>


<style scoped lang="scss">
.vip-inner {
  display: flex;


  .desc-panel {
    padding: 30px;
    width: 290px;
    height: 100%;
    background: #e2e2e2;
    border-radius: 15px 0 0 15px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }

    .sub-title {
      font-size: 15px;
      font-weight: 500;
      margin-top: 20px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #666;
        flex-shrink: 0;
        margin-right: 10px;
      }
    }

  }

  .vip-detail {
    padding: 20px 30px;
    flex: 1;
    height: 100%;
    background: #fff;
    border-radius: 0 15px 15px 0;
    width: 420px;

    .qrcode-container {
      height: 160px;
      background: #f9f9fa;
      border-radius: 15px;
      margin: 20px 0;
      padding: 10px 20px;
      display: flex;
      //align-items: center;

      .qrcode {
        height: 125px;
        width: 125px;
        background: #fff;
        margin-top: 10px;
      }

      .pay-app {
        padding: 5px 15px;
        width: 120px;
        border: 2px solid #f05a24;
        margin-top: 10px;
        border-radius: 8px;
      }

      .discount-price {
        padding: 3px 10px;
        background: rgba(242, 55, 46, 0.1);
        color: #f2372e;
        font-weight: 500;
        font-size: 18px;
        margin-left: 20px;
        border-radius: 5px;
      }

      .price {
        font-size: 22px;
        color: red;
        font-weight: 700;
        margin-left: 13px;
      }
    }

    .footer-desc {
      font-size: 12px;
      color: #a4a3a3
    }

    .details-panel {
      display: flex;

      .details-card-container {
        position: relative;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        margin-left: 22px;
        border-radius: 15px;
        border: 1px solid #f9f9fa;
        width: 200px;
        height: 167px;
        padding: 10px;
        background: #f9f9fa;
        cursor: pointer;

        .discount {
          position: absolute;
          right: 0;
          background: #ff0000d1;
          color: #fff;
          padding: 2px 10px;
          border-radius: 0 10px;
          top: 0;
        }


        .unit {
          font-size: 22px;
          color: red;
          font-weight: 700;
          margin-left: 3px;
        }

        .price {
          font-size: 22px;
          color: red;
          font-weight: 700;
          margin-left: 3px;
        }

        .desc {
          font-size: 12px;
          color: #999999;
          margin-top: 2px;
        }

        .cost-price {
          text-decoration: line-through;
          font-size: 12px;
          margin-left: 10px;
        }
      }

      .details-card-container:hover {
        border: 1px solid #fda69e;
      }

      .details-card-checked {
        border: 1px solid #fda69e;
        background: #fffbf4;
      }

      .details-card-container:first-child {
        margin-left: 0;
      }

      .title {
        font-size: 17px;
        margin-top: 15px;
        font-weight: 600;
        color: #000;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
